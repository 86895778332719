define("discourse/plugins/discourse-multiselect-user-field/discourse/pre-initializers/add-dropdown-type", ["exports", "discourse/lib/plugin-api", "discourse/lib/computed", "ember-addons/ember-computed-decorators"], function (_exports, _pluginApi, _computed, _emberComputedDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const UserFieldType = Ember.Object.extend({
    name: (0, _computed.i18n)("id", "admin.user_fields.field_types.%@")
  });
  var _default = _exports.default = {
    name: 'update-post',
    before: 'inject-discourse-objects',
    initialize() {
      (0, _pluginApi.withPluginApi)('0.8.12', api => {
        var _dec, _dec2, _obj;
        api.modifyClassStatic('model:user-field', {
          fieldTypes() {
            if (!this._fieldTypes) {
              this._fieldTypes = [UserFieldType.create({
                id: "text"
              }), UserFieldType.create({
                id: "confirm"
              }), UserFieldType.create({
                id: "dropdown",
                hasOptions: true
              }), UserFieldType.create({
                id: "multiselect-dropdown",
                hasOptions: true
              })];
            }
            return this._fieldTypes;
          }
        });
        api.modifyClass('component:user-field', (_dec = (0, _emberComputedDecorators.on)("init"), _dec2 = (0, _emberComputedDecorators.observes)("values"), (_obj = {
          values: [],
          initValues() {
            if (this.field.field_type == "multiselect-dropdown" && this.value) {
              try {
                const parsedValue = JSON.parse(this.value);
                this.set("values", parsedValue);
              } catch (e) {
                this.set("values", []);
              }
            }
          },
          setValue() {
            this.set("value", JSON.stringify(this.get("values")));
          }
        }, (_applyDecoratedDescriptor(_obj, "initValues", [_dec], Object.getOwnPropertyDescriptor(_obj, "initValues"), _obj), _applyDecoratedDescriptor(_obj, "setValue", [_dec2], Object.getOwnPropertyDescriptor(_obj, "setValue"), _obj)), _obj)));
      });
    }
  };
});